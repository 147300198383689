/**
 *  User roles
 */

const limitations = {
  UPLOAD_FILE_SIZE: 300, //size in MB
  UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE:
    "File size should not be greater than 300 MB",
  GOOGLE_DOCS_NOT_ALLOWED: "Google docs are not supported",
  UNSUPPORTED_FILE: "The selected type of files are not supported",
};

const gdrivePicker = {
  // Development
  // CLIENT_ID:
  //   "776649487429-e63il5kp1vg5o9rao1mlu35idu8kf6m0.apps.googleusercontent.com",
  // API_KEY: "AIzaSyB3LkPl8_ak_vPeiIweeAG24SpWKYeyowE",

  // Production
  CLIENT_ID:
    "341274599340-bn28rla5dni909o9201odnpi8srt1btd.apps.googleusercontent.com",
  API_KEY: "AIzaSyAuWaULl2mr2_uYMSy-SX0RdAm20VH50nM",
};

const constantValues = {
  FOOT_PRINTS_PAGE_SIZE: 20,
  ACCEPTABLE_FILES:
    "image/*, .gif, .mp4, .mov, .webm, .txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
  ACCEPTABLE_FILES_List:
    ".jpg, .jpeg, .png, .svg, .gif, .mp4, .mov, .webm, .txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
  TRANSITION_DURATION: 600,
  USER_IDLE_TIMEOUT_IN_MINUTES: 30, //30, // in minutes
};

const usersRoleId = {
  FAITH_ADMIN: 7,
  SCHOOL_ADMIN: 4,
  CHURCH_ADMIN: 6,
  CHURCH_PRINCIPAL: 10,
  SCHOOL_PRINCIPAL: 9,
  PASTOR: 5,
  PARENTS: 3,
  TEACHER: 2,
  STUDENT: 1,
};

const usersRoleName = {
  FAITH_ADMIN: "faith admin",
  SCHOOL_ADMIN: "school admin",
  CHURCH_ADMIN: "church admin",
  CHURCH_PRINCIPAL: "church principal",
  SCHOOL_PRINCIPAL: "school principal",
  PASTOR: "pastor",
  PARENTS: "parents",
  TEACHER: "teacher",
  STUDENT: "student",
};

/**
 *  ---
 */

const mixConstants = {
  limitations,
  gdrivePicker,
  constantValues,
  usersRoleId,
  usersRoleName,
};

export default mixConstants;
