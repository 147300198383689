import cookieHelper from "./cookie-helper";

const Persistence = {
  getUserToken,
  setUserToken,
  removeUserToken,

  setUserData,
  getUserData,
  removeUserData,

  setLastSelectedClassOrGroup,
  getLastSelectedClassOrGroup,

  setCurrentUserProfileDataToEdit,
  getCurrentUserProfileDataToEdit,

  setScrollPosition,
  getScrollPosition,
};

/**
 *  User Token
 */

function getUserToken() {
  return cookieHelper.get("jwt");
}

function setUserToken(token) {
  //const date = new Date();
  //const expiresAt = 60 * 24;
  //date.setTime(date.getTime() + expiresAt * 60 * 1000);

  const options = {
    path: "/",
    //expires: date,
    maxAge: 24 * 60 * 60,
  };

  return cookieHelper.set("jwt", token, options);
}

function removeUserToken() {
  return cookieHelper.remove("jwt");
}

/**
 *  User Data
 */

function setUserData(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

function getUserData() {
  return JSON.parse(localStorage.getItem("user"));
}

function removeUserData() {
  localStorage.removeItem("user");
  localStorage.removeItem("lscg");
  localStorage.removeItem("cupd");
}

/**
 *  Others
 */

function setLastSelectedClassOrGroup(classOrGroup) {
  localStorage.setItem("lscg", JSON.stringify(classOrGroup));
}

function getLastSelectedClassOrGroup() {
  return JSON.parse(localStorage.getItem("lscg"));
}

function setCurrentUserProfileDataToEdit(profileData) {
  localStorage.setItem("cupd", JSON.stringify(profileData));
}

function getCurrentUserProfileDataToEdit() {
  return JSON.parse(localStorage.getItem("cupd"));
}

function setScrollPosition(position) {
  localStorage.setItem("sp", position);
}

function getScrollPosition() {
  return localStorage.getItem("sp");
}

export default Persistence;
