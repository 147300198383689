import React from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import txtIcon from "../assets/images/txt.png";
import docIcon from "../assets/images/doc.png";
import docxIcon from "../assets/images/docx.png";
import pptIcon from "../assets/images/ppt.png";
import pptxIcon from "../assets/images/pptx.png";
import xlsIcon from "../assets/images/xls.png";
import xlsxIcon from "../assets/images/xlsx.png";
import pdfIcon from "../assets/images/pdf.png";
import loggedInUserHelper from "./logged-in-user-helper";

const utils = {
  generateId,
  capitalize,
  isValidEmail,
  randomColor,
  userAvatarBackgroundColor,
  bytesToMB,
  fileIcon,
  fileIconImage,
  isSupportedFile,
  fileType,
  javascriptObjectToFormData,
  extractFileNameFromURL,
};

/**
 *  Generate random id
 */

function generateId() {
  const min = 999;
  const max = 9999;
  const rand = min + Math.random() * (max - min);
  return rand;
}

/**
 *  Capitalize string
 */

function capitalize(string) {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

/**
 *  Check email
 */

function isValidEmail(email) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

/**
 *  Get random color
 */

function randomColor() {
  const colors = [
    "F89D00",
    "91C515",
    "A143FF",
    "2AB7FF",
    "FF6E6E",
    "1D62CB",
    "0080C1",
    // new colors
    "107EBF",
    "76002E",
    "F23C26",
    "58626A",
    "1D9635",
    "272C30",
    "E7282A",
    "1C164E",
    "229DA5",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}

/**
 *  Get user avatar background color
 */

function userAvatarBackgroundColor() {
  return loggedInUserHelper.isStudent() ? "229DA5" : "196F50";
}

/**
 *  Get file size in MB
 */

function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

/**
 *  Get file icon
 */

function fileIcon(fileType) {
  if (fileType === "image") {
    return <ImageOutlinedIcon />;
  } else if (fileType === "video") {
    return <OndemandVideoOutlinedIcon />;
  } else if (fileType === "pdf") {
    return <PictureAsPdfOutlinedIcon />;
  } else if (fileType === "text" || fileType === "text/rtf") {
    return <DescriptionOutlinedIcon />;
  } else if (fileType === "office-word") {
    return <DescriptionOutlinedIcon />;
  } else if (fileType === "office-excel") {
    return <DescriptionOutlinedIcon />;
  } else if (fileType === "office-power-point") {
    return <DescriptionOutlinedIcon />;
  } else if (fileType === "office-power-point") {
    return <DescriptionOutlinedIcon />;
  }
}

/**
 *  Get file icon
 */

function fileIconImage(fileType, ext) {
  let fileIcon = null;
  if (fileType === "text") {
    fileIcon = txtIcon;
  } else if (fileType === "pdf") {
    fileIcon = pdfIcon;
  } else if (fileType === "text/rtf") {
    fileIcon = txtIcon;
  } else if (fileType === "office-word") {
    if (ext === "doc") {
      fileIcon = docIcon;
    } else if (ext === "docx") {
      fileIcon = docxIcon;
    }
  } else if (fileType === "office-excel") {
    if (ext === "xls") {
      fileIcon = xlsIcon;
    } else if (ext === "xlsx") {
      fileIcon = xlsxIcon;
    }
  } else if (fileType === "office-power-point") {
    if (ext === "ppt") {
      fileIcon = pptIcon;
    } else if (ext === "pptx") {
      fileIcon = pptxIcon;
    }
  } else {
    fileIcon = docIcon;
  }
  return fileIcon;
}

/**
 *  Is Supported File
 */

function isSupportedFile(ext) {
  const extension = ext.toLowerCase();

  if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "svg" ||
    extension === "gif" ||
    extension === "mp4" ||
    extension === "mov" ||
    //extension === "flv" ||
    extension === "webm" ||
    extension === "txt" ||
    // extension === "rtf" ||
    extension === "docx" ||
    extension === "doc" ||
    extension === "pdf" ||
    extension === "xls" ||
    extension === "xlsx" ||
    extension === "ppt" ||
    extension === "pptx" ||
    extension === "document"
  ) {
    return true;
  }
  return false;
}

/**
 *  Get file type
 */

function fileType(ext) {
  const isImage =
    ext.toLowerCase() === "jpg" ||
    ext.toLowerCase() === "jpeg" ||
    ext.toLowerCase() === "png" ||
    ext.toLowerCase() === "svg" ||
    ext.toLowerCase() === "gif";

  const isVideo =
    ext.toLowerCase() === "mp4" ||
    ext.toLowerCase() === "flv" ||
    ext.toLowerCase() === "mov" ||
    ext.toLowerCase() === "wmv" ||
    ext.toLowerCase() === "avi" ||
    ext.toLowerCase() === "mkv" ||
    ext.toLowerCase() === "webm";

  const isPdf = ext.toLowerCase() === "pdf";

  const isTxt = ext.toLowerCase() === "txt";
  const isRtf = ext.toLowerCase() === "rtf";

  const isOfficeWordDoc =
    ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx";

  const isOfficeExcelDoc =
    ext.toLowerCase() === "xls" ||
    ext.toLowerCase() === "xlsx" ||
    ext.toLowerCase() === "xlm" ||
    ext.toLowerCase() === "xlsm";

  const isOfficePowerPointDoc =
    ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx";

  const isGoogleDoc = ext.toLowerCase() === "document";

  if (isImage) {
    return "image";
  } else if (isVideo) {
    return "video";
  } else if (isPdf) {
    return "pdf";
  } else if (isTxt) {
    return "text";
  } else if (isRtf) {
    return "text/rtf";
  } else if (isOfficeWordDoc) {
    return "office-word";
  } else if (isOfficeExcelDoc) {
    return "office-excel";
  } else if (isOfficePowerPointDoc) {
    return "office-power-point";
  } else if (isGoogleDoc) {
    return "google-document";
  }
}

/**
 *  Java script object to FormData
 */

function javascriptObjectToFormData(formData, data, parentKey) {
  //https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data

  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      javascriptObjectToFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

// function convertModelToFormData(
//   val,
//   formData = new FormData(),
//   namespace = ""
// ) {
//   if (typeof val !== "undefined" && val !== null) {
//     if (val instanceof Date) {
//       formData.append(namespace, val.toISOString());
//     } else if (val instanceof Array) {
//       for (let element of val) {
//         convertModelToFormData(element, formData, namespace + "[]");
//       }
//     } else if (typeof val === "object" && !(val instanceof File)) {
//       for (let propertyName in val) {
//         if (val.hasOwnProperty(propertyName)) {
//           convertModelToFormData(
//             val[propertyName],
//             formData,
//             namespace ? namespace + "[" + propertyName + "]" : propertyName
//           );
//         }
//       }
//     } else {
//       formData.append(namespace, val.toString());
//     }
//   }
//   return formData;
// }

function extractFileNameFromURL(url) {
  // Split the URL by "/" and "?"
  const parts = url.split(/[/?#]/);
  let fileName = parts[parts.length - 2];
  fileName = decodeURIComponent(fileName);
  fileName = fileName.split("?")[0];
  fileName = fileName.split("#")[0];

  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex !== -1) {
    const extension = fileName.slice(dotIndex);
    const nameWithoutExtension = fileName.slice(0, dotIndex);
    // Truncate the file name if it's longer than 10 characters before the extension
    if (nameWithoutExtension.length > 25) {
      fileName = nameWithoutExtension.slice(0, 25) + "..." + extension;
    }
  }
  return fileName;
}

export default utils;
